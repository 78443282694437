body,
input,
select,
textarea,
body * {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}

body::after, body::before,
input::after,
input::before,
select::after,
select::before,
textarea::after,
textarea::before,
body *::after,
body *::before {
    box-sizing: border-box;
}
.w-35{
    width: 35%;
}
.container-flex {
    display: flex;
    column-gap: 20px;
    margin-bottom: -30px;
}

.container-flex1 {
    display: flex;
    column-gap: 20px;
    margin-top: -30px;
}

.date-flex2 {
    display: flex;
    column-gap: 20px;
}

.container-flex3 {
    display: flex;
    column-gap: 20px;
    margin-bottom: -30px;
    margin-top: 10px;

}

.flex-identity {
    display: flex;
    column-gap: 20px;
    margin-top: -25px;
}

.date-flex2 .form-group {
    margin: 0;
}

h1 {
    font-size: 2rem;
    text-align: center;
    margin-top: 10px;
    color: #333;
}

img {
    max-width: 100%;
    height: auto;
}

/*tr {*/
/*    text-align : center;*/
/*    vertical-align: middle;*/
/*}*/
@media screen and (max-width: 992px) {
    .container-flex {
        display: contents;
    }

    .date-flex2 {
        display: flex;
    }

    .flex-identity {
        display: contents;
    }

    .container-flex3 {
        display: contents;
    }

    .main-container {
        padding: 1rem !important;
    }

    h1 {
        font-size: 1.2rem;
        text-align: center;
        margin-top: 10px;
        color: #333;
    }

    .note {
        font-size: 8px;
    }

    .medical_label, .vaccine_label {
        font-size: 0.7rem;
    }
}

.vaccine_label,
.register_for label,
.vaccine_detail label {
    display: block;
}

.register {
    margin-top: 30px;
    display: flex;
}

.vaccine_detail {
    margin-top: 30px;

}

.register input,
.vaccine_detail input {
    margin-right: 10px;
}

.form-group {
    width: 100%;
}

.right p {
    display: block;
    margin: 0;
    padding: 0;
    color: #5d6b71;
}

.main-container {
    /*position: relative;*/
    /*margin: 10px auto;*/
    /*background: rgb(250, 249, 249);*/
    width: 100%;
    padding: 3rem 5rem 0;
    /*border-radius: 1px;*/
    /*box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);*/
    /*-webkit-transform: scale(0.98);*/
    /*transform: scale(0.98);*/
}

.fb-container {
    padding: 2rem 0 0 2rem;
}

.main {
    position: relative;
    margin: 10px auto;
    background: rgb(250, 249, 249);
    width: 100%;
    /*padding: 3rem 5rem 0;*/
    border-radius: 1px;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
}

.main-form {
    display: inline;
}

/* .container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  -webkit-transition: -webkit-transform 0.28s ease-in-out;
  transition: -webkit-transform 0.28s ease-in-out;
  transition: transform 0.28s ease-in-out;
  transition: transform 0.28s ease-in-out, -webkit-transform 0.28s ease-in-out;
  z-index: -1;
} */
.container:hover::before {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.button-container {
    text-align: center;
    margin-bottom: 10px !important;
}

fieldset {
    margin: 0 0 3rem;
    padding: 0;
    border: none;
}

.form-radio,
.form-group {
    position: relative;
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
}

.form-inline > .form-group,
.form-inline > .btn {
    display: inline-block;
    margin-bottom: 0;
}

.form-help {
    margin-top: 0.125rem;
    margin-left: 0.125rem;
    color: #b3b3b3;
    font-size: 0.8rem;
}

.checkbox .form-help, .form-radio .form-help, .form-group .form-help {
    position: absolute;
    width: 100%;
}

.checkbox .form-help {
    position: relative;
    margin-bottom: 1rem;
}

.form-radio .form-help {
    padding-top: 0.25rem;
    margin-top: -1rem;
}

.form-group input {
    height: 1.9rem;
}

.form-group textarea {
    resize: none;
}

.form-group select {
    width: 100%;
    font-size: 1rem;
    height: 1.6rem;
    padding: 0.125rem 0.125rem 0.0625rem;
    background: none;
    border: none;
    line-height: 1.6;
    box-shadow: none;
}
.date-control {
    position: absolute;
    top: 0.25rem;
    pointer-events: none;
    padding-left: 0.125rem;
    z-index: 1;
    color: #b3b3b3;
    font-size: 1rem;
    font-weight: normal;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
}
.form-group .control-label {
    position: absolute;
    top: 0.25rem;
    pointer-events: none;
    padding-left: 0.125rem;
    z-index: 1;
    color: #b3b3b3;
    font-size: 1rem;
    font-weight: normal;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
}

.form-group .bar {
    position: relative;
    border-bottom: 0.0625rem solid #999;
    display: block;
}

.form-group .bar::before {
    content: '';
    height: 0.125rem;
    width: 0;
    left: 50%;
    bottom: -0.0625rem;
    position: absolute;
    background: #337ab7;
    -webkit-transition: left 0.28s ease, width 0.28s ease;
    transition: left 0.28s ease, width 0.28s ease;
    z-index: 2;
}

.form-group input,
.form-group textarea {
    display: block;
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 1rem;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    color: transparent;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
    box-shadow: none;
}

.form-group input[type="file"] {
    line-height: 1;
}

.form-group input[type="file"] ~ .bar {
    display: none;
}

.form-group select,
.form-group input:focus,
.form-group input:valid,
.form-group input.form-file,
.form-group input.has-value,
.form-group textarea:focus,
.form-group textarea:valid,
.form-group textarea.form-file,
.form-group textarea.has-value {
    color: #333;
}

.form-group select ~ .control-label,
.form-group input:focus ~ .control-label,
.form-group input:valid ~ .control-label,
.form-group input.form-file ~ .control-label,
.form-group input.has-value ~ .control-label,
.form-group textarea:focus ~ .control-label,
.form-group textarea:valid ~ .control-label,
.form-group textarea.form-file ~ .control-label,
.form-group textarea.has-value ~ .control-label
.form-group textarea.has-value ~ .date-control {

    font-size: 0.8rem;
    color: gray;
    top: -1rem;
    left: 0;
}

.form-group select:focus,
.form-group input:focus,
.form-group textarea:focus {
    outline: none;
}

.form-group select:focus ~ .control-label,
.form-group input:focus ~ .control-label,
.form-group textarea:focus ~ .control-label {
    color: #337ab7;
}

.form-group select:focus ~ .bar::before,
.form-group input:focus ~ .bar::before,
.form-group textarea:focus ~ .bar::before {
    width: 100%;
    left: 0;
}

.checkbox label,
.form-radio label {
    position: relative;
    cursor: pointer;
    padding-left: 2rem;
    text-align: left;
    color: #333;
    display: block;
}

.checkbox input,
.form-radio input {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
}

.radio {
    margin-bottom: 1rem;
}

.radio, .helper {
    position: absolute;
    top: -0.25rem;
    left: -0.25rem;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #999;
}

.radio .helper::before, .radio .helper::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 0.25rem;
    width: 1rem;
    height: 1rem;
    -webkit-transition: -webkit-transform 0.28s ease;
    transition: -webkit-transform 0.28s ease;
    transition: transform 0.28s ease;
    transition: transform 0.28s ease, -webkit-transform 0.28s ease;
    border-radius: 50%;
    border: 0.125rem solid currentColor;
}

.radio .helper::after {
    -webkit-transform: scale(0);
    transform: scale(0);
    background-color: #337ab7;
    border-color: #337ab7;
}

.radio label:hover .helper {
    color: #337ab7;
}

.radio input:checked ~ .helper::after {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
}

.radio input:checked ~ .helper::before {
    color: #337ab7;
}

.checkbox {
    margin-top: 0.9rem;
    margin-bottom: 1rem;
}

.checkbox .helper {
    color: #999;
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    z-index: 0;
    border: 0.125rem solid currentColor;
    border-radius: 0.0625rem;
    -webkit-transition: border-color 0.28s ease;
    transition: border-color 0.28s ease;
}

.checkbox .helper::before, .checkbox .helper::after {
    position: absolute;
    height: 0;
    width: 0.2rem;
    background-color: #337ab7;
    display: block;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    border-radius: 0.25rem;
    content: '';
    -webkit-transition: opacity 0.28s ease, height 0s linear 0.28s;
    transition: opacity 0.28s ease, height 0s linear 0.28s;
    opacity: 0;
}

.checkbox .helper::before {
    top: 0.65rem;
    left: 0.38rem;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    box-shadow: 0 0 0 0.0625rem #fff;
}

.checkbox .helper::after {
    top: 0.3rem;
    left: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.checkbox label:hover .helper {
    color: #337ab7;
}

.checkbox input:checked ~ .helper {
    color: #337ab7;
}

.checkbox input:checked ~ .helper::after, .checkbox input:checked ~ .helper::before {
    opacity: 1;
    -webkit-transition: height 0.28s ease;
    transition: height 0.28s ease;
}

.checkbox input:checked ~ .helper::after {
    height: 0.5rem;
}

.checkbox input:checked ~ .helper::before {
    height: 1.2rem;
    -webkit-transition-delay: 0.28s;
    transition-delay: 0.28s;
}

.radio + .radio,
.checkbox + .checkbox {
    margin-top: 1rem;
}

.has-error .legend.legend, .has-error.form-group  {
    color: #d9534f;
}

.has-error.form-group .form-help,
.has-error.form-group .helper, .has-error.checkbox .form-help,
.has-error.checkbox .helper, .has-error.radio .form-help,
.has-error.radio .helper, .has-error.form-radio .form-help,
.has-error.form-radio .helper {
    color: #d9534f;
}

.has-error .bar::before {
    background: #d9534f;
    left: 0;
    width: 100%;
}

.button {
    position: relative;
    background: currentColor;
    border: 1px solid currentColor;
    font-size: 1.1rem;
    color: #4f93ce;
    margin: 3rem 0;
    padding: 0.75rem 3rem;
    cursor: pointer;
    -webkit-transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
    transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.button span {
    color: #fff;
    position: relative;
    z-index: 1;
}

.button::before {
    content: '';
    position: absolute;
    background: #071017;
    border: 50vh solid #1d4567;
    width: 30vh;
    height: 30vh;
    border-radius: 50%;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 0;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
}

.button:hover {
    color: #337ab7;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.button:active::before, .button:focus::before {
    -webkit-transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
    transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
    transition: transform 1.12s ease, opacity 0.28s ease 0.364s;
    transition: transform 1.12s ease, opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
}

.button:focus {
    outline: none;
}

.help-block {
    font-size: 12px;
}

#detailTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#detailTable td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#detailTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.date-label {
    font-size: 0.8rem !important;
    color: gray !important;
    top: -1rem !important;
    left: 0 !important;
}

.btn {
    border: none;
    padding: 8px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    color: white;
    border-radius: 4px;
}

.btn-save {
    background-color: #4CAF50;
}

.btn-edit {
    background-color: #e7e7e7;
    color: black;
}

/*.btn-cancel {*/
/*    background-color: #f44336;*/
/*}*/
.btn:hover {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.note {
    background-color: #e7f3fe;
    border-left: 6px solid #2196F3;
    column-gap: -20px;
    padding: 3px;
}

footer {
    background-color: #92b1cd;
    padding: 2px;
}

.date-type {
    width: 100%;
    position: relative;
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
}

.date-type .control-label {
    pointer-events: none;
    font-size: 0.8rem;
    font-weight: normal;
    color: gray;
    top: -1rem;
    left: 0;
    display: block;
    position: absolute;
    padding-left: 0.125rem;
}

.date-type input {
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 1rem;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    color: transparent;
    box-shadow: none;
}

.news {
    width: 160px
}

.news-scroll a {
    text-decoration: none
}

.dot {
    height: 6px;
    width: 6px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 2px !important;
    background-color: rgb(207, 23, 23);
    border-radius: 50%;
    display: inline-block
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.language {
    display: flex;
}

.d-flex .form-group,
.d-flex .date-type{
    width: 50%;
    justify-content: center;
    align-self: center;
}
.qr{
    width: 50%;
    margin: 0 auto;
}
section .container-fluid{
    padding-top: 10px;
}
.mr-3{
    margin-right: 20px;
}
.vaccination{
    display: flex;
}
.bg-blue{
   background-color: #4f93ce !important;
}
.btn-search{
    max-width: 120px;
    float: right;

}
.input-group-addon{
    margin-top: -26px;
}
.login-image{
    margin: 0 auto;
}
.red{
    color: crimson;
}
.green{
    color: forestgreen;
}
.bg-blue-secondary {
    background-color: #0087c7!important;
}
.custom-shape-divider-bottom-1625728591 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1625728591 svg {
    position: relative;
    display: block;
    width: calc(154% + 1.3px);
    height: 500px;
}

.custom-shape-divider-bottom-1625728591 .shape-fill {
    fill: #4A64E22B;
}
.transparent{
    background-color: #ffffff6b;
}
.blue {
    color: #3490dc;
  }
  
  .indigo {
    color: #6574cd;
  }
  
  .purple {
    color: #9561e2;
  }
  
  .pink {
    color: #f66D9b;
  }
  
  .red {
    color: #e3342f;
  }
  
  .orange {
    color: #f6993f;
  }
  
  .yellow {
    color: #ffed4a;
  }
  
  .green {
    color: #38c172;
  }
  
  .teal {
    color: #4dc0b5;
  }
  
  .cyan {
    color: #6cb2eb;
  }
  
  .black {
    color: #2d2d2d;
  }
  table.dataTable thead>tr>th.sorting_asc,table.dataTable thead>tr>th.sorting_desc,table.dataTable thead>tr>th.sorting,table.dataTable thead>tr>td.sorting_asc,table.dataTable thead>tr>td.sorting_desc,table.dataTable thead>tr>td.sorting {
    padding-right: 30px
}

table.dataTable thead>tr>th:active,table.dataTable thead>tr>td:active {
    outline: none
}

table.dataTable thead .sorting,table.dataTable thead .sorting_asc,table.dataTable thead .sorting_desc,table.dataTable thead .sorting_asc_disabled,table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative
}

table.dataTable thead .sorting:after,table.dataTable thead .sorting_asc:after,table.dataTable thead .sorting_desc:after,table.dataTable thead .sorting_asc_disabled:after,table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: block;
    font-family: "Glyphicons Halflings";
    opacity: .5
}

table.dataTable thead .sorting:after {
    opacity: 0.2;
    font-family: "Font Awesome 5 Free"; font-weight: 900;
    content: "\f0dc";
}

table.dataTable thead .sorting_asc:after {
    font-family: "Font Awesome 5 Free"; font-weight: 900;
    content: "\f0dc";
}

table.dataTable thead .sorting_desc:after {
    font-family: "Font Awesome 5 Free"; font-weight: 900; 
        content: "\f0dc";
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
@media (max-width: 1024px) and (min-width:768px) {
    .search-item label{
        font-size: 14px !important;
    }
    .table-bordered th, .table-bordered td {
        font-size: 12px;
    }
}  

@media (max-width: 678px) {
    .register{
        flex-direction:column;
    }
    .register-for-item{
        margin-bottom: 10px;
    }
    .mark u{
        font-size: 22px;
    }
    .vaccination{
        flex-direction: column;
    }.search{
        flex-direction: column;
    }
}
@media (max-width: 425px) and (min-width: 320px) {
    .language a,span{
        font-size: 11px;
        vertical-align: text-top;
    }    
}
@media (max-width: 320px) {
    .language a,span{
        font-size: 10px;
        vertical-align: text-top;
    }
    
    
}
