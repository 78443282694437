@import "~bootstrap";
@import "formulate.css";


.mar-t{
    margin-top: 0.1rem !important;
    margin-bottom: 1rem !important;
}

.vacc-add{
    margin-bottom: 0px !important;
}

.card-css{
    margin-bottom: 0px !important;
}
.w-35{
    width: 35% !important;
}
